$white: #ffffff;
$primary: #9c56e0;
$gris01: #f6f6f6;
$gris02: #e5e5e5;
$gris03: #bcbcbc;
$gris04: #555555;
$gris05: #313131;
// hover
$hoverPrimary: #EDF2F0;

$shadowLarge: 0px 2px 15px rgba(0, 0, 0, 0.1), 0px 15px 40px rgba(0, 0, 0, 0.08);


.container {
	list-style: none;
	width: 100%;
	padding: 0.5rem 1.5rem 0.25rem;
	z-index: 8;
	color: #000;

	font-family: "NT Bau";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px;
	letter-spacing: -0.5px;
	
	&__active {
		 cursor: pointer;
		 color: $gris04;
		 & a {
				color: $gris04;
				text-decoration: none;
		 }
		 &:hover {
				background: $hoverPrimary;
				color: $primary;
		 }
		 &:hover a {
				color: $primary;
		 }
	}
	&__disabled {
		 cursor: not-allowed;
		 color: $gris03;
		 & a {
				color: $gris03;
				text-decoration: none;
		 }
		 &:hover {
				background: transparent;
				color: $gris03;
		 }
		 &:hover a {
				color: $gris03;
		 }
	}
}