.wrapper {
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr;
  grid-gap: 56px;
  background: #1e5349;
  padding: 87px 170px 82px;

  .header {
    .title {
      font-size: 48px;
			font-family: Roca One;
			font-weight: 400;
			line-height: 55px;
			letter-spacing: 1.2px;
			text-align: center;
      color: #ffffff;

      span {
        color: #FFAA7D;
      }
    }
  }

  .body {
    display: flex;
    flex-direction: row;
    gap: 136px;
    width: 1120px;
    max-width: 1100px;
    margin: 0 auto;

    .form {
      width: 100%;
      display: flex;
      flex-direction: column;
			gap: 32px;

      .row {
        display: flex;
        flex-direction: column;

        .label {
          color: #fff;
          font-size: 20px;
					font-family: NT Bau;
					font-weight: 400;
					line-height: 130%;
					letter-spacing: -2%;
          margin-bottom: 16px;
        }

        button,
        a {
          margin-top: 40px;
					height: 54px !important;
        }

      }

      .rowColumns {
        display: flex;
        flex-direction: row;
        gap: 16px;

        .prefixColumn {
          display: flex;
          flex-direction: column;
          min-width: 150px;
        }

        .phoneColumn {
          display: flex;
          flex-direction: column;
          width: 100%;
        }

        .label {
          color: #fff;
          font-size: 20px;
					font-family: NT Bau;
					font-weight: 400;
					line-height: 130%;
					letter-spacing: -2%;
          margin-bottom: 16px;
        }
      }
    }

    .aside {
      width: 426px;
      min-width: 414px;
      display: flex;
      flex-direction: column;
      gap: 24px;
			padding-top: 43px;
			gap: 72px;

			.image {
				border-radius: 30px;
			}
    }
  }
}

@media only screen and (max-width: 768px) {
  .wrapper {
    padding: 32px 20px 48px;
		gap: 32px;
		box-sizing: border-box;

    .header {
      display: flex;
      flex-direction: column;
      gap: 12px;
			width: 100%;			

      .title {
        font-size: 30px;
				line-height: 110%;
				letter-spacing: 0.6px;
      }
    }

    .body {
			flex-direction: column-reverse;
      gap: 32px;
      width: 100%;
      max-width: 100%;
			margin: 0;

      .form {
        .row {
          min-height: 50px;

          button,
          a {
            margin-top: 48px;
						height: 48px !important;
          }
        }
      }

      .aside {
        width: 100%;
        min-width: 100%;
				padding-top: 0;

				.image {
					display: none;
				}
      }
    }
  }
}
