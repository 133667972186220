$gris01: #f6f6f6;
$gris02: #e5e5e5;
$gris03: #bcbcbc;
$gris04: #555555;
$gris05: #313131;

.container {
	display: flex;
	border-radius: 16px;
	border: 2px solid #B9BFBD;
	background: #FCFFFE;
	position: relative;

	.text {
		 background: transparent;
		 color: $gris05;
		 padding: 15px 16px 17px 24px;
		 width: calc(100% - 2.25rem);
		 overflow: hidden;
		 
		 .placeholder {
			font-family: NT Bau;
			font-size: 18px;
			line-height: 122%;
			color: #808080;
		 }

		 .ellipsis {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
		 }
		 .textDisabled {
				color: $gris05;
		 }
		 .textGris {
				color: #808080;
		 }
		 .textBlack {
				color: $gris05;
		 }
	}

	.icon {
		 background: transparent;
		 color: $gris05;
		 padding: 24px 24px 0 8px;
		 cursor: pointer;

		 &__default {
				padding-right: 24px;
		 }
		 &__small {
				padding-right: 0.8rem;
		 }
		 &__disabled {
				cursor: not-allowed;
		 }
	}

	&__disabled {
		 border: 1px solid $gris03;
		 border-radius: 0.375rem;
		 cursor: not-allowed;

		 .select-text {
				color: $gris02 !important;
		 }

		 &:hover {
				color: $gris02;
				border: 1px solid $gris03;
		 }
	}
	.dropdown {
		 position: absolute;
		 width: 100%;
		 top: 70px;
	}
	.dropdown li:last-child {
		 border-bottom: none;
	}
}

@media only screen and (max-width: 768px) {
	.container {
		border: 1px solid #B9BFBD;
	
		.text {
			padding: 10px 12px 12px;
			 			 
			 .placeholder {
				font-size: 14px;
			 }
		}

		.icon {
			padding: 20px 15px 0 8px;
		}
	}
}