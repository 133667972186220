$white: #ffffff;
$primary: #9c56e0;
$gris01: #f6f6f6;
$gris02: #e5e5e5;
$gris03: #bcbcbc;
$gris04: #555555;
$gris05: #313131;
// hover
$hoverPrimary: #EDF2F0;

$shadowLarge: 0px 2px 15px rgba(0, 0, 0, 0.1), 0px 15px 40px rgba(0, 0, 0, 0.08);

.container {
  position: relative;
  border-radius: 0.5rem;
  padding: 0.5rem 0 0.45rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.6875rem;
  cursor: pointer;

  position: relative;
  overflow: hidden;
  box-shadow: $shadowLarge;
  background: $white;
  margin: 0;
  border-radius: 0.5rem;
  box-sizing: border-box;
  z-index: 100;
  top: -4px;

  &--open {
    width: auto;
    min-width: 100%;
  }
  &--close {
    width: 2.875rem;
  }
  &--active {
    border: 1.25px solid $primary;
  }
  &--inactive {
    border: 1.25px solid $gris03;
  }
  .modal {
    position: relative;
    overflow: hidden;
    box-shadow: $shadowLarge;
    background: $white;
    margin: 0;
    border-radius: 0.5rem;
    box-sizing: border-box;
    z-index: 100;
    top: -4px;

    &--open {
      bottom: 0;
      right: -12.125rem;
      z-index: 99;
    }
    &--close {
      bottom: 0;
      left: 3.1rem;
      z-index: 99;
    }
    &__default {
      padding-bottom: 0;
    }
    &__small {
      padding-bottom: 0;
    }
    &--isScroll {
      height: 17.25rem;
      padding-bottom: 1.5rem;
    }
  }
  .modalContent {
    width: 100%;
    margin: 0;
    padding: 0;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    &::-webkit-scrollbar {
      background: $white;
      width: 8px;
      border: 1px solid $white;
    }
    &::-webkit-scrollbar-thumb {
      background: $gris03;
      border-radius: 1.25rem;
      border: 5px solid $gris03;
    }
  }
  .noContent {
    padding: 0 1rem;
    font-family: NT Bau;
    font-size: 14px;
    line-height: 20px;
    color: $gris03;
  }
  &__modal-option {
    font-family: NT Bau;
    list-style: none;
    cursor: pointer;
    font-size: 14px;
    line-height: 21px;
    color: $gris04;
    padding: 0.5rem 0.625rem 0.25rem 0.625rem;
    &:hover {
      color: $primary;
      background: $hoverPrimary;
    }
    &--disabled {
      color: $gris02;
      cursor: not-allowed;
      &:hover {
        color: $gris02;
        background: $white;
      }
    }
  }
  /* 33 x item */
  /* 20 de padding */
  &__modal-qty-items3 {
    height: 119px;
  }
  &__modal-qty-items4 {
    height: 152px;
  }
  &__modal-qty-items5 {
    height: 183px;
  }
  &__modal-qty-items6 {
    height: 215px;
  }
  &__modal-qty-items7 {
    height: 247px;
  }
  &__modal-qty-items8 {
    height: 282px;
  }
  &__modal-qty-items9 {
    height: 314px;
  }
}
