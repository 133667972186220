.customSelect {
  width: 150px;
  position: relative;
  user-select: none;

  img {
    width: 20px;
    height: 20px;
  }

  .selectedOption {
    width: 150px;
    height: 60px;
    background-color: #fff;
    border: 2px solid #B9BFBD;
    border-radius: 16px;
    padding: 16px 32px 18px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;

    .arrow {
      position: absolute;
      right: 20px;
      top: 24px;
      transition: transform 0.3s ease;
      transform: rotate(0);

      &__isOpen {
        transform: rotate(180deg);
      }
    }

    input {
      width: 60px;
			pointer-events: none;
			font-family: NT Bau;
			font-size: 18px;
			line-height: 122%;

			&:focus {
				outline: none;
			}
    }
  }

  .optionsContainer {
    position: absolute;
    width: 100%;
    border: 1px solid #ccc;
    background: #fff;
    z-index: 10;
    max-height: 300px;
    overflow-y: scroll;
    border-radius: 8px;
    margin-top: 4px;

    & ::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 10px; /* Radio de la esquina para la pista de la barra */
    }
    & ::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 10px; /* Radio de la esquina para el control deslizante */
    }

    .optionItem {
      padding: 10px 20px 10px 32px;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 10px; /* Espacio entre el icono y el texto */
    }

    .optionItem:hover {
      background-color: #f0f0f0;
    }
  }

  .optionsContainer::-webkit-scrollbar {
    width: 12px;
    height: 100px;
  }
  .optionsContainer::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }
  .optionsContainer::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }
}

@media only screen and (max-width: 768px) {
	.customSelect {

		.selectedOption {
			border-radius: 12px;
			border: 1px solid #B9BFBD;
			padding: 12px 24px 12px 24px;
			height: 48px;

			.arrow {
				top: 19px;
			}

			input {
				width: 45px;
				font-size: 14px;
				line-height: 15px;
				letter-spacing: 0%;
				text-align: right;
			}
		}
	}
}